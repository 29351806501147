
body {
	width: 100%;
	padding: 0;
	margin: 0;
	font-family: "Weissenhof Grotesk Regular", Helvetica, Arial, Sans-Serif;
}

/* variabler */

$green: #29B473;

/* styling */

a {
	text-decoration: none;
	color: inherit;
	cursor:pointer;
}

a:hover{
	color: #29B473;
}

h2 {
	font-size: 2em;
	font-family: "Weissenhof Grotesk Regular", Helvetica, Arial, Sans-Serif;
}

h3 {
	color: #FFF;
	font-family: "Weissenhof Grotesk Regular", Helvetica, Arial, Sans-Serif;
	font-size: 1.2em;
}

.white-tekst {
	color: #000;
}

p {
	font-size: 1.1em;
	max-width: 650px;
}


td {
	vertical-align: top;
}


.arrow-white{
	color: #fff;
	padding-right: 8px;
}

.arrow-green{
	color: $green;
	padding-right: 8px;
}

.underline-white {
	margin-top: -10px;
	border-bottom: 2px solid #FFF;
	width: 60px;
}

.underline-green {
	margin-top: -10px;
	border-bottom: 2px solid $green;
	width: 60px;
}

.underline-black {
	border-bottom: 4px solid #000;
	width: 80px;
}

.underline-bottom-white {
	border-bottom: 1px solid #FFF;
	margin-top: -14px;
}

.underline-bottom-green {
	border-bottom: 1px solid $green;
	margin-top: -14px;
}

.underline-text-white {
	border-bottom: 1px solid white;
    display: inline;
}

.underline-text-green {
	border-bottom: 1px solid $green;
    display: inline;
}

.black-text {
	color: #000;
}

/* Header/navbar */

header{
	width: 84%;
	padding: 2% 8% 2% 8%;
	position: fixed;
	z-index: 100000;
	background-color: #FFF;

}

nav {
	float: right;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

li {
    float: left;
    font-size: 1.5em;
    padding-top: 6px;
}

li a {
    display: block;
    color: #000;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}


.logoname {
	color: #000;
 	float: left;
	margin-left: 20px;
	margin-top: 14px;
	margin-bottom: 0px;
	font-size: 2em;
}

.logo {
	height: 60px;
	float:left;
}

.language {
	float: right;
	margin-top: 25px;
}


/* Container */


.container{
	width: 100%;
	position: absolute;
	margin-top: 115px;
}

.wrapper{
	width: 100%;
	float: left;
	height: auto;
	display: flex;
}

.left{
	width: 50%;
	float: left;
	align-items: stretch;
	flex: 1
}

.img1 {
	background: url(img/Gulevester.jpg) no-repeat center center; 
    background-size: cover;
}

.img2 {
	background: url(img/Sveising.jpg) no-repeat center center; 
    background-size: cover;
}

.img3 {
	background: url(img/Byggeplaner.jpg) no-repeat center center; 
    background-size: cover;
}

.img4 {
	background: url(img/Dokumenter.jpg) no-repeat center center;  
    background-size: cover;
}


.h-patter {
	background: url(img/H-Repeat-Pattern-Left.svg) no-repeat; 
	background: url(img/H-Repeat-Pattern-Left.png) no-repeat; 
    background-size: cover;
    z-index: 10000;
    background-color: $green;
}

.h-patter-right {
	background: url(img/H-Repeat-Pattern-right.svg) no-repeat; 
	background: url(img/H-Repeat-Pattern-right.png) no-repeat; 
	background-size: cover;
    z-index: 10000;
    background-color: $green;
    }

.h-patter-mob
	{
    background: url(); 
    background-size: cover;
    z-index: 10000;
    background-color: $green;
}


.right{
	width: 50%;
	float: right;
	align-items: stretch;
	flex: 1
}

.collapse {
	max-height: 800px;
	 -webkit-transition: max-height 0.25s ease-in;;  
   	-moz-transition: max-height 0.25s ease-in;
  	-o-transition: max-height 0.25s ease-in;
  	transition: max-height 0.25s ease-in; 
}

.collapse.ng-hide {
	max-height: 0px;
	 -webkit-transition: max-height 0.25s ease-out;;  
   	-moz-transition: max-height 0.25s ease-out;
  	-o-transition: max-height 0.25s ease-out;
  	transition: max-height 0.25s ease-out; 
}

.hide-on-desktop {
	display: none;
}


.image {
	width: 100%;
}

.pattern {
	margin-top: -60px;
	width: 40%;
	position: absolute;
	z-index: 1000;
}

.pattern2 {
	width: 68%;
	position: relative;
	z-index: 1000;
	margin-left: 16%;
	margin-top: 20%;
}

.top {
	background-color: $green;
	padding: 16% 16% 16% 16%;
	height: auto;
}

.top a:hover{
	color: #fff;
}

.bottom {
	background-color: #000;
	color: #FFF;
	padding: 16% 16% 16% 16%;
	height: auto;
}

.middle {
	padding: 20% 16% 20% 16%;
	background-color: $green;
	height: auto;
}

.bg-white {
	background-color: #FFF;
}

.bg-black {
	background-color: #000;
	color: #FFF;
}

.nobgcolor {
	background-color: transparent;
}

.nobgcolor a:hover{
	color: #fff;
}

#map {
        height: 100%;
}

ng-map.mapstyling {
	height: 100%;
	min-height: 400px;
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right:0;
}


@media (max-width: 1500px) {
		.top {
		background-color: $green;
		padding: 5% 16% 10% 16%;
		height: auto;

	}

	.bottom {
		background-color: #000;
		color: #FFF;
		padding: 5% 16% 10% 16%;
		height: auto;
	}

	.middle {
	padding: 10% 16% 10% 16%;
}

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {

  	.right{
	width: 50%;
	min-height: 100%;
	float: right;
	align-items: stretch;
	flex: 1
	}

	.left{
	width: 50%;
	min-height: 100%;
	float: left;
	align-items: stretch;
	flex: 1
	}

	.middle {
    padding: 10% 16% 16% 16%;
}

}


@media (max-width: 900px) {


	header {
    padding: 4% 8% 4% 8%;
}

	.wrapper {
	display: block;
	}


	li {
	padding-top: 8px;
    font-size: 1.2em;
	}

	li a {
    padding: 14px 0px 14px 30px;
	}

	.logoname {
    margin-top: 18px;
    font-size: 1.6em;
	}

	h2 {
	font-size: 1.8em;
	}


	.left {
	    width: 100%;
	}

	.right {
	    width: 100%;
	}

	.hide-on-desktop {
	display: block;
	}

	.hide-on-mobile {
	display: none;
	}


	button.accordion, button.accordion2 {
	    background-color: transparent;
	    color: #ffffff;
	    cursor: pointer;
	    width: 100%;
	    border: none;
	    text-align: left;
	    transition: 0.4s;
	    font-family: "Weissenhof Grotesk Regular", Helvetica, Arial, Sans-Serif;
	    font-size: 0.9em;
	    padding: 0px;
	}

	button.accordion:after {
	    content: ' ';
	    background: url('img/Pil-jobb.svg') no-repeat;
	    background: url('img/Pil-jobb.png') no-repeat;
	    background-size: 35px 35px;
	    float: right;
	    width: 35px;
	    height: 35px;
	    border: none;
	    margin-top: -50px;
	    -webkit-transition: 0.25s ease-in;
    	-moz-transition: 0.25s ease-in;
    	-o-transition: 0.25s ease-in;
    	transition: 0.25s ease-in;
	}

	button.accordion2:after {
	    content: ' ';
	    background: url('img/Pil-personell.svg');
	    background: url('img/Pil-personell.png');
	    background-size: 35px 35px;
	    float: right;
	    width: 35px;
	    height: 35px;
	    border: none;
		margin-top: -50px;
		-webkit-transition: 0.25s ease-in;
    	-moz-transition: 0.25s ease-in;
    	-o-transition: 0.25s ease-in;
    	transition: 0.25s ease-in;

	}

	button.accordion.rotate:after, button.accordion2.rotate:after {
	 	-webkit-transform: rotate(90deg);
    	-moz-transform: rotate(90deg);
    	-o-transform: rotate(90deg);
    	-ms-transform: rotate(90deg);
    	transform: rotate(90deg);   
	}

	.button.accordion .h2 {
	font-size: 2em;
	}

	button:focus {
	outline:0;
	}


	.top {
    padding: 6% 8% 8% 8%;	
	}

	.bottom {
    padding: 6% 8% 8% 8%;	
	}

	.middle {
    padding: 8% 8% 12% 8%;	
	}

	.no-pad-bottom{
    padding: 8% 8% 0% 8%;	
	}

	.pattern2 {
	display: none;
	}


	.img1 {
	    height: 550px;
	}

	.img2 {
	    height: 550px;
	}

	.img3 {
	    height: 550px;
	    float: right;
	}

	.img4 {
	    height: 550px;
	}

	.h-patter {
		background: url(img/H-Repeat-Pattern-Left.svg) no-repeat center center; 
		background: url(img/H-Repeat-Pattern-Left.png) no-repeat center center; 
    	background-size: cover;
    	z-index: 10000;
    	background-color: $green;
	}

	.h-patter {
		background: url() no-repeat center center; 
	    background-size: none;
	    z-index: 10000;
	    background-color: $green;
	}

		.h-patter-mob{
	    background: url(img/H-Repeat-Pattern-Left.svg) no-repeat; 
	    background: url(img/H-Repeat-Pattern-Left.png) no-repeat; 
	    background-size: cover;
	    z-index: 10000;
	    background-color: $green;
	}

	.mapstyling {
	height: 500px;
	}

}


@media (max-width: 650px) {

	header {
    padding: 4% 8% 0% 8%;
    }

    .logo {
    height: 40px
    }

	.logoname {
    margin-top: 8px;
    font-size: 1.4em;
	}

	nav {
		clear:both;
		float: left;
	}

	li {
    font-size: 1.0em;
    padding: 5px 0px 0px 0px;
	}

	h2 {
    font-size: 1.5em;
	}

	p, span {
    font-size: 1.0em;
}


	ul li a{
        padding-left:0!important;
        padding-right: 30px;
	}

	ul li a {
    padding-left: 0 !important;
    padding-right: 30px;
	}


	.top {
    padding: 3% 8% 3% 8%;	
	}

	.bottom {
    padding: 3% 8% 3% 8%;	
	}

	.panel{
	padding-bottom: 8%;
	}


	.img1 {

	    height: 200px;
	}

	.img2 {
	    height: 300px;
	}

	.img3 {
	    height: 300px;
	}

	.img4 {
	    height: 300px;
	}

	
}



